import React, { useEffect, useState } from 'react';

function SignIn() {



    useEffect(() => {


    }, []);
    return (
        <>
            <section className="flex flex-col md:flex-row h-screen items-center">
                <div className="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
                    <img
                        src="/login.png"
                        alt=""
                        className="w-full h-full object-cover"
                    />
                </div>
                <div
                    className="bg-white w-full lg:max-w-full md:mx-auto md:mx-0 px-6 lg:px-16 xl:px-12 flex items-center justify-center"
                >
                   
                    <div className="w-full h-screen">
                 
                        <nav className="text-sm sm:text-base bg-white p-4 md:p-6 lg:p-6 rounded-md">
                            <ol className="list-none p-0 inline-flex space-x-2">
                                <li className="flex items-center">
                                    <svg
                                        onClick={() => { window.location.href = '/'; }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 576 512"
                                        className="cursor-pointer hover:fill-green-500 transition-colors duration-300"
                                        fill="#4b5563"
                                    >
                                        {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                                        <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                                    </svg>{" "}
                                    <svg
                      className="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                                </li>
                                <li className="flex items-center">
                                    <a
                                        href="/sign-in"
                                        className="text-gray-600 hover:text-green-500 transition-colors duration-300"
                                    >
                                        Sign In
                                    </a>
                                </li>
                            </ol>
                        </nav>
                        <a className="flex flex-col text-3xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-3xl md:text-3x1 lg:text-3x1" href='/' >
                            CHAT TO FLOW
                        </a>
                        <p className="mt-8">
                            Need an account? <br />
                            <a
                                href="/sign-up"
                                className="text-green-500 hover:text-green-700 font-semibold"
                            >
                                Create an account
                            </a>
                        </p>
                        <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
                            Log in to your account
                        </h1>
                        <form className="mt-6" action="#" method="POST">
                            <div>
                                <label className="block text-gray-700">Email Address</label>
                                <input
                                    type="email"
                                    name=""
                                    id=""
                                    placeholder="Enter Email Address"
                                    className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-green-500 focus:bg-white focus:outline-none"
                                    autoComplete=""
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block text-gray-700">Password</label>
                                <input
                                    type="password"
                                    name=""
                                    id=""
                                    placeholder="Enter Password"
                                    minLength={6}
                                    className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-green-500 focus:bg-white focus:outline-none"
                                />
                            </div>
                            <div className="text-right mt-2">
                                <a
                                    href="/"
                                    className="text-sm font-semibold text-gray-700 hover:text-green-700 focus:text-green-700"
                                >
                                    Forgot Password?
                                </a>
                            </div>
                            <a
                                    href='/home'
                                    className="mt-6 rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32"
                                >
                                    Log in
                                </a>
                        </form>
                        <hr className="my-6 border-gray-300 w-full" />
                        <button
                            type="button"
                            className="w-full block bg-white hover:bg-gray-100 focus:bg-gray-100 text-gray-900 font-semibold rounded-lg px-4 py-3 border border-gray-300"
                        >
                            <div className="flex items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    className="w-6 h-6"
                                    viewBox="0 0 48 48"
                                >
                                    <defs>
                                        <path
                                            id="a"
                                            d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                                        />
                                    </defs>
                                    <clipPath id="b">
                                        <use xlinkHref="#a" overflow="visible" />
                                    </clipPath>
                                    <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
                                    <path
                                        clipPath="url(#b)"
                                        fill="#EA4335"
                                        d="M0 11l17 13 7-6.1L48 14V0H0z"
                                    />
                                    <path
                                        clipPath="url(#b)"
                                        fill="#34A853"
                                        d="M0 37l30-23 7.9 1L48 0v48H0z"
                                    />
                                    <path
                                        clipPath="url(#b)"
                                        fill="#4285F4"
                                        d="M48 48L17 24l-4-3 35-10z"
                                    />
                                </svg>
                                <span className="ml-4">Log in with Google</span>
                            </div>
                        </button>
                        
                    </div>
                </div>
            </section>

        </>);


}
export default SignIn;