import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import DataTable from '../../components/Datatable';

function UsersList() {

    const [showModal, setShowModal] = useState(0);
    const [showModal2, setShowModal2] = useState(0);
    const [showModal3, setShowModal3] = useState(0);
    const [columns, setColumns] = useState([] as any);
    const [data, setData] = useState([] as any);

    useEffect(() => {
    }, []);
    return (
        <>
            <div className="h-screen">
                <div className=" bg-white flex flex-col bg-clip-border rounded-xl m-2">
                    <div className="p-4 block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5  ">
                        <div className="w-full mb-1">
                            <div className="mb-4">
                                <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                                    Listing Users
                                </h1>
                            </div>
                            <div className="sm:flex">
                                <div className="items-center sm:flex-cols-1 flex mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0">
                                    <form className="lg:pr-3" action="#" method="GET">
                                        <label htmlFor="Users-search" className="sr-only">
                                            Search
                                        </label>
                                        <div className="relative mt-1 lg:w-64 xl:w-96">
                                            <input
                                                type="text"
                                                name="email"
                                                id="Users-search"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                                placeholder="Search for Users"
                                            />
                                        </div>
                                    </form>

                                    <button

                                        className="inline-flex justify-center p-1 text-dark rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100   "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            width={20}
                                            height={20}
                                            viewBox="0 0 48 48"
                                        >
                                            <path
                                                fill="#616161"
                                                d="M34.6 28.1H38.6V45.1H34.6z"
                                                transform="rotate(-45.001 36.586 36.587)"
                                            />
                                            <path fill="#616161" d="M20 4A16 16 0 1 0 20 36A16 16 0 1 0 20 4Z" />
                                            <path
                                                fill="#37474F"
                                                d="M36.2 32.1H40.2V44.400000000000006H36.2z"
                                                transform="rotate(-45.001 38.24 38.24)"
                                            />
                                            <path fill="#64B5F6" d="M20 7A13 13 0 1 0 20 33A13 13 0 1 0 20 7Z" />
                                            <path
                                                fill="#BBDEFB"
                                                d="M26.9,14.2c-1.7-2-4.2-3.2-6.9-3.2s-5.2,1.2-6.9,3.2c-0.4,0.4-0.3,1.1,0.1,1.4c0.4,0.4,1.1,0.3,1.4-0.1C16,13.9,17.9,13,20,13s4,0.9,5.4,2.5c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2C27.2,15.3,27.2,14.6,26.9,14.2z"
                                            />
                                        </svg>

                                    </button>
                                    <div className="flex ">

                                    </div>
                                </div>
                                <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
                                    <button
                                        type="button"
                                        data-modal-toggle="add-User-modal"
                                        className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-primary-300 sm:w-auto  "
                                        onClick={() => { showModal3 === 0 ? setShowModal3(1) : setShowModal3(0) }}
                                    >
                                        <svg
                                            className="w-5 h-5 mr-2 -ml-1"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        Add User
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto custom-scrollbar ">
                            <div className="inline-block min-w-full align-middle">
                                <div className="overflow-hidden shadow h-full">
                                    <DataTable columns={[
                                        { Header: 'Image', accessor: 'image' },
                                        { Header: 'Name', accessor: 'name' },
                                        { Header: 'Billing Day', accessor: 'billingDay' },
                                        { Header: 'Plan', accessor: 'plan' },
                                        { Header: 'Subscriber', accessor: 'subscriber' },
                                        { Header: 'Actions', accessor: 'actions' },

                                    ]} data={[

                                        <>
                                            <img
                                                className="w-10 h-10 rounded-full"
                                                src="perfil.jpg"
                                                alt=" avatar"
                                            />
                                        </>
                                        ,
                                        <>
                                            <div className="text-sm font-normal text-gray-500 ">
                                                <div className="text-base font-semibold text-gray-900">
                                                    Charlie R
                                                </div>
                                                <div className="text-sm font-normal text-gray-500 ">
                                                    Development
                                                </div>
                                            </div>
                                        </>
                                        ,
                                        <>
                                            10
                                        </>
                                        ,
                                        <>
                                            <select disabled
                                                id="countries_disabled"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 disabled text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="0">Free</option>
                                                <option value="1" selected>Basic</option>
                                                <option value="2">Team</option>
                                                <option value="3">Company</option>
                                                <option value="4">Custom</option>
                                            </select>
                                        </>
                                        ,
                                        <> <div className="flex items-center">
                                            {/*  <div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2" /> */}
                                            {<div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2" />}
                                        </div>
                                        </>
                                        ,
                                        <>  <button
                                            type="button"
                                            data-modal-toggle="edit-User-modal"
                                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-blue-500 hover:bg-blue-400 focus:ring-4 focus:ring-primary-300"
                                            onClick={() => { showModal === 0 ? setShowModal(1) : setShowModal(0) }}
                                        >
                                            <svg
                                                width="14px"
                                                height="14px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                fill="#ffffff"
                                                stroke="#ffffff"
                                            >
                                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                                <g id="SVGRepo_iconCarrier">
                                                    {" "}
                                                    <g
                                                        id="🔍-System-Icons"
                                                        stroke="none"
                                                        strokeWidth={1}
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        {" "}
                                                        <g id="ic_fluent_eye_show_24_filled" fill="#ffffff" fillRule="nonzero">
                                                            {" "}
                                                            <path
                                                                d="M12,9.0046246 C14.209139,9.0046246 16,10.7954856 16,13.0046246 C16,15.2137636 14.209139,17.0046246 12,17.0046246 C9.790861,17.0046246 8,15.2137636 8,13.0046246 C8,10.7954856 9.790861,9.0046246 12,9.0046246 Z M12,5.5 C16.613512,5.5 20.5960869,8.65000641 21.7011157,13.0643865 C21.8017,13.4662019 21.557504,13.8734775 21.1556885,13.9740618 C20.7538731,14.0746462 20.3465976,13.8304502 20.2460132,13.4286347 C19.3071259,9.67795854 15.9213644,7 12,7 C8.07693257,7 4.69009765,9.68026417 3.75285786,13.4331499 C3.65249525,13.8350208 3.24535455,14.0794416 2.84348365,13.979079 C2.44161275,13.8787164 2.19719198,13.4715757 2.29755459,13.0697048 C3.4006459,8.65271806 7.38448293,5.5 12,5.5 Z"
                                                                id="🎨-Color"
                                                            >
                                                                {" "}
                                                            </path>{" "}
                                                        </g>{" "}
                                                    </g>{" "}
                                                </g>
                                            </svg>

                                            View User
                                        </button>
                                            <button
                                                type="button"
                                                data-modal-toggle="delete-User-modal"
                                                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-800 focus:ring-4 focus:ring-red-300 "
                                                onClick={() => { showModal2 === 0 ? setShowModal2(1) : setShowModal2(0) }}
                                            >
                                                <svg
                                                    className="w-4 h-4 mr-2"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                Delete User
                                            </button></>
                                        ,

                                    ]} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky bottom-0 right-0 items-center w-full p-4 border-t border-gray-200 sm:flex sm:justify-between  ">
                        <div className="flex items-center mb-4 sm:mb-0">
                            <a
                                href="#"
                                className="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100   "
                            >
                                <svg
                                    className="w-7 h-7"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                            <a
                                href="#"
                                className="inline-flex justify-center p-1 mr-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100   "
                            >
                                <svg
                                    className="w-7 h-7"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                            <span className="text-sm font-normal text-gray-500 ">
                                Showing{" "}
                                <span className="font-semibold text-gray-900">
                                    1-1
                                </span>{" "}
                                of{" "}
                                <span className="font-semibold text-gray-900">
                                    1
                                </span>
                            </span>
                        </div>

                    </div>
                </div>

            </div>
            < Modal show={showModal === 1 ? '' : 'hidden'} title={
                <>
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold">View User</h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm"
                            data-modal-toggle="edit-User-modal"
                            onClick={() => { showModal === 0 ? setShowModal(1) : setShowModal(0) }}
                        >
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>

                </>
            }
                list={
                    <>
                        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2  sm:p-6 ">
                            <h3 className="mb-4 text-xl font-semibold ">
                                General information
                            </h3>
                            <form action="#">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="first-name"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                            placeholder="Bonnie"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="last-name"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="Green"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="country"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Country
                                        </label>
                                        <input
                                            type="text"
                                            name="country"
                                            id="country"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="United States"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="city"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="e.g. San Francisco"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="address"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Address
                                        </label>
                                        <input
                                            type="text"
                                            name="address"
                                            id="address"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="e.g. California"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="email"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="example@company.com"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="phone-number"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Phone Number
                                        </label>
                                        <input
                                            type="number"
                                            name="phone-number"
                                            id="phone-number"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="e.g. +(12)3456 789"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="birthday"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Birthday
                                        </label>
                                        <input
                                            type="number"
                                            name="birthday"
                                            id="birthday"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="15/08/1990"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="organization"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Organization
                                        </label>
                                        <input
                                            type="text"
                                            name="organization"
                                            id="organization"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="Company Name"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="role"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Role
                                        </label>
                                        <input
                                            type="text"
                                            name="role"
                                            id="role"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="React Developer"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="department"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Department
                                        </label>
                                        <input
                                            type="text"
                                            name="department"
                                            id="department"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="Development"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="zip-code"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Zip/postal code
                                        </label>
                                        <input
                                            type="number"
                                            name="zip-code"
                                            id="zip-code"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "


                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="department"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Department
                                        </label>
                                        <input
                                            type="text"
                                            name="department"
                                            id="department"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "
                                            placeholder="Development"

                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="zip-code"
                                            className="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Zip/postal code
                                        </label>
                                        <input
                                            type="number"
                                            name="zip-code"
                                            id="zip-code"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5   "


                                        />
                                    </div>
                                  
                                    <div className="col-span-6 sm:col-full">
                                        <button
                                            className="text-dark bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 border border-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:text-green-700"
                                            type="submit"
                                        >
                                            Save all
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                }
                footer={
                    <>
                        <button
                            className=" text-white border-2 bg-green-500 hover:bg-primary-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                            type="submit"
                        >
                            Save all
                        </button>
                    </>
                }
            />
            < Modal show={showModal2 === 1 ? '' : 'hidden'} title={
                <>
                    <div className='inline-flex'>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                            data-modal-toggle="delete-user-modal"
                            onClick={() => { showModal2 === 0 ? setShowModal2(1) : setShowModal2(0) }}
                        >
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button></div>
                </>
            }
                list={
                    <>
                        <svg
                            className="w-16 h-16 mx-auto text-red-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                        <h3 className="mt-5 mb-6 text-lg text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this user?
                        </h3>
                        <a
                            href="#"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2 dark:focus:ring-red-800"
                        >
                            Yes, I'm sure
                        </a>
                        <button
                            onClick={() => { showModal2 === 0 ? setShowModal2(1) : setShowModal2(0) }}
                            className="text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 border border-gray-200 font-medium inline-flex items-center rounded-lg text-base px-3 py-2.5 text-center dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                            data-modal-toggle="delete-user-modal"
                        >
                            No, cancel
                        </button>
                    </>
                }
                footer={
                    <>

                    </>
                }
            />


            < Modal show={showModal3 === 1 ? '' : 'hidden'} title={
                <>    <div className='inline-flex'>

                    <h3 className="text-xl font-semibold dark:text-white">Add New User</h3><div className='w-[80px] sm:w-[150px] md:w-[150px] lg:w-[250px]'></div>
                    <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                        data-modal-toggle="add-user-modal"
                        onClick={() => { showModal3 === 0 ? setShowModal3(1) : setShowModal3(0) }}
                    >
                        <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                </>
            }
                list={
                    <>
                        <div className="col-span-6 sm:col-span-3">
                            <label
                                htmlFor="first-name"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                First Name
                            </label>
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="Bonnie"

                            />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label
                                htmlFor="last-name"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="Green"

                            />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label
                                htmlFor="email"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="example@company.com"

                            />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label
                                htmlFor="position"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Position
                            </label>
                            <input
                                type="text"
                                name="position"
                                id="position"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="e.g. React developer"

                            />
                        </div>
                        <div className="col-span-6">
                            <label
                                htmlFor="biography"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Biography
                            </label>
                            <textarea
                                id="biography"
                                rows={4}
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="👨‍💻Full-stack web developer. Open-source contributor."
                                defaultValue={""}
                            />
                        </div>
                    </>
                }
                footer={
                    <>
                        <button
                            className=" text-white border-2 bg-green-500 hover:bg-primary-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
                            type="submit"
                        >
                            Add User
                        </button>
                    </>
                }
            />


        </>
    );


}
export default UsersList;