import React, { useEffect, useState } from 'react';

function SignUp() {



    useEffect(() => {


    }, []);
    return (
        <>
            <section className="flex flex-col md:flex-row h-screen items-center bg-white">
                <div className="flex w-screen md:w-full">
                    <div className="hidden sm:d-none md:flex md:w-4/4 w-4/4">
                        <div className="w-full h-screen">
                            <img
                                src="/SignUp.gif"
                                alt=""
                                className="flex h-full object-unset"
                            />

                        </div>

                    </div>
                    <div className="w-full h-screen md:w-2/5 w-3/3">
                    <nav className="text-sm sm:text-base bg-white md:p-6 mt-4 ml-2 lg:p-6 rounded-md">
                            <ol className="list-none p-0 inline-flex space-x-2">
                                <li className="flex items-center">
                                    <svg
                                        onClick={() => { window.location.href = '/'; }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 576 512"
                                        className="cursor-pointer hover:fill-green-500 transition-colors duration-300"
                                        fill="#4b5563"
                                    >
                                        {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                                        <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                                    </svg>{" "}
                                    <svg
                      className="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                                </li>
                                <li className="flex items-center">
                                    <a
                                        href="/sign-up"
                                        className="text-gray-600 hover:text-green-500 transition-colors duration-300"
                                    >
                                        Sign Up
                                    </a>
                                </li>
                            </ol>
                        </nav>
                        <div className="mx-auto w-full max-w-md pb-1 px-8 sm:px-0">
                            <div className="relative mt-5">
                                <div
                                    className="absolute left-0 top-2 h-0.5 w-full bg-gray-200"
                                    aria-hidden="true"
                                >
                                    <div className="absolute h-full w-1/3 bg-gray-200" />
                                    {/* change to w-2/3 for next step */}
                                    <div className="left absolute left-1/3 h-full w-1/3 bg-gradient-to-r from-gray-200" />
                                    {/* change to left-1/2 for next step */}
                                </div>
                                <ul className="relative flex w-full justify-between">
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-green-400 text-xs font-semibold text-white ring ring-green-600 ring-offset-2"
                                        >
                                            1
                                        </a>
                                    </li>
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-300 text-xs font-semibold text-white"
                                        >
                                            2
                                        </a>
                                    </li>
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-300 text-xs font-semibold text-white"
                                        >
                                            3
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="my-auto mx-auto flex flex-col justify-center px-6 md:justify-start lg:w-[28rem]">
                            <p className="text-center text-3xl font-bold md:text-left md:leading-tight mt-2">
                                Create your free account
                            </p>
                            <p className="mt-3 text-center font-medium md:text-left">
                                Already using Chat To Flow? <br />
                                <a href="/sign-in" className="whitespace-nowrap font-semibold text-green-500 hover:text-green-700">
                                    Login here
                                </a>
                            </p>
                            <button className="-2 mt-4 flex items-center justify-center rounded-md border px-4 py-1 outline-none ring-gray-400 ring-offset-2 transition hover:border-transparent hover:bg-black hover:text-white focus:ring-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    className="w-6 h-6"
                                    viewBox="0 0 48 48"
                                >
                                    <defs>
                                        <path
                                            id="a"
                                            d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                                        />
                                    </defs>
                                    <clipPath id="b">
                                        <use xlinkHref="#a" overflow="visible" />
                                    </clipPath>
                                    <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
                                    <path
                                        clipPath="url(#b)"
                                        fill="#EA4335"
                                        d="M0 11l17 13 7-6.1L48 14V0H0z"
                                    />
                                    <path
                                        clipPath="url(#b)"
                                        fill="#34A853"
                                        d="M0 37l30-23 7.9 1L48 0v48H0z"
                                    />
                                    <path
                                        clipPath="url(#b)"
                                        fill="#4285F4"
                                        d="M48 48L17 24l-4-3 35-10z"
                                    />
                                </svg> <span className="ml-4">Get started with Google</span>
                            </button>
                            <div className="relative mt-8 flex h-px place-items-center bg-gray-200 mb-6">
                                <div className="whitespace-nowrap absolute left-1/2 h-6 -translate-x-1/2 bg-white px-4 text-center text-sm text-gray-500">
                                    Or use email instead
                                </div>
                            </div>
                                <div className="flex flex-col mb-4 ">
                                    <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-green-600">
                                        <input
                                            type="text"
                                            id="login-name"
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="relative mb-4">
                                        <label htmlFor="on-error-email" className="text-gray-700">
                                            Email
                                            <span className="text-red-500 required-dot">*</span>
                                        </label>
                                        <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-green-600">
                                        <input
                                            type="text"
                                            id="on-error-email"
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            name="email"
                                            placeholder="Your email"
                                        />
                                       {/*  <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={15}
                                            height={15}
                                            fill="currentColor"
                                            className="absolute text-red-500 right-2 bottom-3"
                                            viewBox="0 0 1792 1792"
                                        >
                                            <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z"></path>
                                        </svg> */}
                                        <p className="absolute text-sm text-red-500 -bottom-6">Email is invalid</p>
                                    </div>
                                    </div>

                                </div>
                                <div className="mb-4 flex flex-col mb-4">
                                    <div className=" relative ">
                                        <label htmlFor="with-indications" className="text-gray-700">
                                            Password
                                            <span className="text-red-500 required-dot">*</span>
                                        </label>
                                        <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-green-400">
                                       <input
                                            type="password"
                                            id="with-indications"
                                            className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                                            name="passwor"
                                            placeholder="Password"
                                        />
                                        </div>
                                        <div className="grid w-full h-1 grid-cols-12 gap-4 mt-3">
                                            <div className="h-full col-span-3 bg-gray-200 rounded "></div>
                                            <div className="h-full col-span-3 bg-gray-200 rounded "></div>
                                            <div className="h-full col-span-3 bg-gray-200 rounded "></div>
                                            <div className="h-full col-span-3 bg-gray-200 rounded "></div>
                                        </div>
                                        {/* <div className="mt-2 text-green-500">Valid password</div> */}
                                        <div className="mt-2 text-red-500">Invalid password</div>
                                    
                                    </div>

                                </div>
                                <div className="block">
                                    <input
                                        className="mr-2 h-5 w-5 appearance-none rounded border border-gray-300 bg-contain bg-no-repeat align-top text-black shadow checked:bg-green-400 focus:border-green-400 focus:shadow actived"
                                        type="checkbox"
                                        id="remember-me"
                                        style={{
                                            backgroundImage:
                                                'url("data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3e%3cpath fill="none" stroke="%23fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 10l3 3l6-6"/%3e%3c/svg%3e")'
                                        }}
                                    />
                                    <small className="inline-block" >
                                        {" "}
                                        I agree to the{" "}
                                        <a className="underline" href="#">
                                            Terms and Conditions
                                        </a>
                                    </small>
                                </div>
                                <a
                                    href='/pricing'
                                    className="mt-6 rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32"
                                >
                                    Sign up
                                </a>
                        </div>
                    </div>
                </div>
            </section>

        </>);


}
export default SignUp;