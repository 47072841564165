import React, { useEffect, useState  } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate  } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
/* import Cookies from "universal-cookie"; */
import NavBar from './components/Navbar';
import SignIn from './views/Auth/SignIn';
import SignUp from './views/Auth/SignUp';
import Payment from './views/Auth/Payment';
import Chat from './views/Plans/Basic/Chat';
import Pricing from './views/Auth/Pricing';
import Home from './views/Home';
import Profile from './views/Users/ProfileSettings';
import AdminList from './views/Users/AdminList';
import UsersList from './views/Users/UsersList';
import PlansList from './views/Plans/PlansList';
import NotificationList from './views/Users/NotificationList';
import Kanban from './views/Plans/Basic/kanban';
/* import Auth from './services/Auth';
import Modals from './components/Modal/Modals';
import Loading from './components/Utils/Loading'; */

/* const cookies = new Cookies(); */
const rootElement = document.getElementById('root'); 

  function LoadingBar({ ...rest }) {
  
    const [loading, setLoading] = useState(false);
    const [modalLoadShow, setModalLoadShow] = useState(false);
   
    
    useEffect(() => {
      // subscribe to home component messages
     /*  const subscription = Loading.onLoading().subscribe((loading: any) => {
        if (loading) {
                setLoading(loading);
                setModalLoadShow(true)
              } else {
                setLoading(false);
                setModalLoadShow(false)
            }
        });
        return subscription.unsubscribe; */
    }, []);
    return (
        <>
          {/*   
            <Modals
            show={loading}
            size={"1px"}
            fullscreen={false}
            title={
            <>
              <div className='text-center text-white'>
              <small>CARREGANDO</small>
              </div>
            </>
            }
            text={
              <>
              <div className='text-center text-white'>
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                    
              </div>
            </>     
            }
            footer={
              <>
               <div className='text-end end-div mb-2 text-white'>
                <small className='mb-4'>Aguarde...</small>
              </div>    
              </>
            }
            />
             */}
                
        </>
    )
  }
  if (rootElement !== null) {
  createRoot(rootElement).render(
    <BrowserRouter>
    < LoadingBar/>
      <div className='wrapper' style={{ background: "#dad3cc" }}>
        <NavBar/>
        <div className='main' style={{ background: "#dad3cc" }}>
          <PrivateRoute />
        </div>
      </div>
    </BrowserRouter>
  );
}

function PrivateRoute() {
  
  const [token, setToken] = useState(null);
  let navigate = useNavigate();

  useEffect(() => { 
  
    /* const subscription = Auth.observable.onToken().subscribe((token: any) => {
      if(token)
      {
        console.info('Fazendo Login');
        setToken(token);
        navigate('/home');
      }
      if(token === null)
      {
        console.info('Fazendo Logout');
        setToken(null);
        navigate('/login');
      }
    });
    Auth.observable.setToken(Auth.getToken());
    return subscription.unsubscribe; */
  }, []);

    return (
    <>
      <Routes>
        <>
        {/* {token === null ?
        (<> */}
        <Route path='/sign-in' element={<SignIn />}/>
        <Route path='/sign-up' element={<SignUp />}/>
        <Route path='/chat' element={<Chat />}/>
        <Route path='/pricing' element={<Pricing />}/>
        <Route path='/pricing-plan' element={<Payment />}/>
        <Route path='/profile-settings' element={<Profile />}/>
        <Route path='/home' element={<Home />}/>
        <Route path='/kanban' element={<Kanban />}/>
        <Route path='/adm-list' element={<AdminList />}/>
        <Route path='/user-list' element={<UsersList />}/>
        <Route path='/plans-list' element={<PlansList />}/>
        <Route path='/notification-list' element={<NotificationList />}/>
        <Route path='/' element={<App />}/>
      {/*   </>)
        :
        (<>
        <Route path='/login' element={<App />}/>
        <Route path='*' element={<Navigate to="/" />}/>
        </>)
      
        } */}
        </>
      </Routes>
    </>
  /*  */
    );
  }

reportWebVitals();