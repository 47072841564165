
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

function NotificationsNav({ ...propos }) {


  const location = useLocation();
  useEffect(() => {

  }, []);
  return (
    <>
      {/* component */}
      {/* This is an example component */}
      <div className={`max-w-2xl mx-auto border-2 border-xl  ${propos['show'] === 0 ? 'hidden' : 'block absolute z-20'}`} style={{ right: "140px" }}>
        <aside className="w-64 border-2 border-xl " aria-label="Sidebar">
          <div className="px-3 py-4 overflow-y-auto rounded bg-gray-100 ">
            <label className='flex text-sm items-center justify-center'> 2 New Notifications</label>
            <ul className="space-y-2">
              <li className='flex flex-cols-1 bg-blue-50' >
                <a className='flex flex-cols-1 bg-blue-50' href='#'>
                  <div className="pr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="mt-2 h-5 w-5 text-blue-500">
                      <path fillRule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div>
                    <div className="text-sm pb-2">
                    Attention, Administrator!
                      <span className="float-right">

                      </span>
                    </div>
                    <div className="text-sm text-gray-600 tracking-tight ">
                      We have identified that a new pre-subscriber has just sent a message to your platform. 
                      This is an excellent... {/* opportunity for engagement and initial conversation. 
                      We recommend checking the message and responding promptly to provide additional information about your services or to guide the user through the subscription process. */}
                    </div>
                  </div>
                </a>
              </li>
              <li className='flex flex-cols-1 bg-green-50'>
              <a className='flex flex-cols-1 bg-green-50' href='#'>
                <div className="pr-2">
                  <svg
                    className="fill-current text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={22}
                    height={22}
                  >
                    <path
                      className="heroicon-ui"
                      d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                    />
                  </svg>
                </div>
                <div>
                  <div className="text-sm pb-2">
                  Hello, Administrator! 

                  </div>
                  <div className="text-sm text-gray-600  tracking-tight ">
                  A new user has subscribed to the basic plan in the system. 
                  Please review... {/* the details of the new registration and, if necessary, 
                  reach out to provide personalized support or offer additional information about the available features. */}
                  </div>
                </div>
              </a>
              </li>

              <li className='flex flex-cols-1 bg-red-50'>
              <a className='flex flex-cols-1 bg-red-50' href='#'>
                <div className="pr-2">
                  <svg
                    className="fill-current text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                  >
                    <path
                      className="heroicon-ui"
                      d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.54a5 5 0 0 1 7.08 0 1 1 0 0 1-1.42 1.42 3 3 0 0 0-4.24 0 1 1 0 0 1-1.42-1.42zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                    />
                  </svg>
                </div>
                <div>
                  <div className="text-sm pb-2">
                  Hello, Administrator.

                  </div>
                  <div className="text-sm text-gray-600  tracking-tight ">
                  We regret to inform you that a subscriber to the basic plan has canceled their subscription in the system. 
                  We recommend... {/* reviewing the logs to understand the reasons behind the cancellation and considering initiatives for customer retention. 
                  If you need further information or assistance, we are available to help improve the user experience and reduce churn rates. */}
                  </div>
                </div>
              </a>
              </li>
              <li>

              </li>
            </ul>
            <a href='#'><label className='flex text-xs items-center justify-center cursor-pointer'> View All Notifications</label></a>
            
          </div>
        </aside >

      </div >
    </>);


}
export default NotificationsNav;