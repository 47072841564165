
import React, { useEffect, useState } from 'react';
function Modal({ ...props }) {



	useEffect(() => {


	}, []);
	return (
		<>
			<div
				className={`absolute w-full bg-gray-300 bg-opacity-75 grid grid-cols z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal rounded border-xl ${props['show']}`}
				id="edit-admin-modal"
			>
				<div className=" relative w-screen h-full max-w-4xl md:h-auto border-xl rounded-full">
					<div className="relative bg-white rounded-lg shadow bg-gray-100 border-xl  rounded-full">
						<div className="flex items-start justify-between p-5 rounded-t bg-gray-100 border-xl ">
							<h3 className="text-xl font-semibold bg-gray-100 ">{props['title']}</h3>
							{props['buttonExit']}
						</div>
						<div className={`p-6 space-y-6 block border-xl ${props['colorbackground'] !== undefined ? props['colorbackground'] : 'bg-gray-100'}`}  > 
							<div className="grid grid-cols-4 gap-2 bg-gray-100">
							{props['list']}
							</div>
						</div>
						<div className="items-center p-6 text-dark rounded-b bg-gray-100 border-xl">
							{props['footer']} 
						</div>
					</div>
				</div>
			</div>
		</>
	);


}
export default Modal;